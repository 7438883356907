import { NotificationContext, useCancellablePromise } from '@mid-react-common/common';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { AccProject, BIMAccount } from 'mid-types';
import { BIM360LocalStorageKeys, CustomError, UnexpectedError } from 'mid-utils';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';
import ModelSelectionContext from '../../../context/ModelSelectionStore/ModelSelection.context';
import ProductContext from '../../../context/ProductStore/Product.context';
import text from '../../../global/text.json';
import { routes } from '../../../routes';

interface UseProjectSelectorPanelProps {
  accounts: BIMAccount[] | undefined;
  handleProjectMenuClose: () => void;
}

interface UseProjectSelectorPanel {
  accountId: string | null;
  accountDisplayName: string | undefined;
  accountImageURL: string | undefined;
  projectId: string | null;
  projectName: string | null;
  handleAccountSelection: (account: BIMAccount) => void;
  handleProjectSelection: (project: AccProject) => void;
  handleChangeAccount: () => void;
}
const projectSelectorText = text.accountProjectSelector;

export const useProjectSelectorPanel = ({
  accounts,
  handleProjectMenuClose,
}: UseProjectSelectorPanelProps): UseProjectSelectorPanel => {
  const {
    accountId,
    projectId,
    accountDisplayName,
    accountImageURL,
    accountIdInCurrentProject,
    projectName,
    setCurrentProject,
    setCurrentAccount,
    clearAccountData,
    clearProjectData,
  } = useContext(AccountProjectContext);
  const { resetModelSelectionStoreState } = useContext(ModelSelectionContext);
  const { resetProductStoreState } = useContext(ProductContext);
  const cancellablePromise = useCancellablePromise();

  const projectByIdLoading = useRef<boolean>(false);
  const findAccountBelongingToProjectTriggered = useRef<boolean>(false);

  const { projectId: projectIdURLParam } = useParams();
  const { logAndShowNotification } = useContext(NotificationContext);

  const navigate = useNavigate();

  const forgeApiService = getForgeApiServiceInstance();

  const findAccountBelongingToProject = useCallback(
    (accounts: BIMAccount[], accountIdInCurrentProject: string): BIMAccount => {
      // We use the id inside the project details to retrieve
      // & set the account info We do not store the account info,
      // as this can be retrieved & we don't have to do additional
      // validation on a persisted accountId
      const accountBelongingToProject = accounts.find((account) => account.id === accountIdInCurrentProject);
      findAccountBelongingToProjectTriggered.current = true;
      if (!accountBelongingToProject) {
        throw new UnexpectedError(projectSelectorText.couldNotFindAccountBelongingToProject, {
          context: { accounts, accountIdInCurrentProject, projectId },
        });
      }

      return accountBelongingToProject;
    },
    [projectId],
  );

  // Set the currentProject if there is a projectID in the URL
  // or local storage
  useEffect(() => {
    const executeAsync = async () => {
      // projectId in URL takes #1 precedence
      if (projectIdURLParam && projectIdURLParam !== projectId) {
        projectByIdLoading.current = true;

        const project = await cancellablePromise(forgeApiService.getProjectById(projectIdURLParam));

        if (project) {
          setCurrentProject(project);
        } else {
          // we do not want to remove the selected project id in
          // local storage here as it may be valid in the next logic block
          clearProjectData({ updateLocalStorage: false });
          logAndShowNotification({
            error: projectSelectorText.couldNotFindProject,
            logExtraData: { projectId: projectIdURLParam },
          });
        }
      } else {
        // projectId in local storage takes #2 precedence
        const projectIdInLocalStorage = window.localStorage.getItem(BIM360LocalStorageKeys.SELECTED_PROJECT_ID);
        if (projectIdInLocalStorage && projectIdInLocalStorage !== projectIdURLParam && !projectId) {
          projectByIdLoading.current = true;

          const project = await cancellablePromise(forgeApiService.getProjectById(projectIdInLocalStorage));

          if (project) {
            setCurrentProject(project);
          } else {
            clearProjectData({ updateLocalStorage: true });
            logAndShowNotification({
              error: projectSelectorText.couldNotFindProject,
              logExtraData: { projectId: projectIdInLocalStorage },
            });
          }
        }
      }
    };

    // prevent multiple 'getProjectById' requests
    if (accounts && !projectByIdLoading.current) {
      executeAsync();
    }
  }, [
    accounts,
    forgeApiService,
    clearProjectData,
    logAndShowNotification,
    projectId,
    projectIdURLParam,
    setCurrentProject,
    cancellablePromise,
  ]);

  useEffect(() => {
    // Deep link provided the projectId, but we don't have the account yet
    if (accounts?.length && !accountId && accountIdInCurrentProject && !findAccountBelongingToProjectTriggered.current) {
      try {
        const accountBelongingToProject = findAccountBelongingToProject(accounts, accountIdInCurrentProject);
        setCurrentAccount(accountBelongingToProject);
      } catch (err) {
        clearAccountData();
        clearProjectData({ updateLocalStorage: true });
        if (err instanceof CustomError) {
          logAndShowNotification({ error: err });
        } else {
          throw err;
        }
      }
    }
  }, [
    accountId,
    accountIdInCurrentProject,
    accounts,
    clearAccountData,
    clearProjectData,
    findAccountBelongingToProject,
    logAndShowNotification,
    setCurrentAccount,
  ]);

  const handleAccountSelection = (account: BIMAccount) => {
    setCurrentAccount(account);
    clearProjectData({ updateLocalStorage: true });
  };

  const handleProjectSelection = (project: AccProject) => {
    setCurrentProject(project);
    const projectIdPath = generatePath(routes.projectId.path, { projectId: project.id });
    navigate(projectIdPath);
    handleProjectMenuClose();

    resetModelSelectionStoreState();
    resetProductStoreState();
  };

  const handleChangeAccount = () => {
    clearAccountData();
    clearProjectData({ updateLocalStorage: true });
    resetModelSelectionStoreState();
    resetProductStoreState();
  };

  return {
    accountId,
    accountDisplayName,
    accountImageURL,
    projectId,
    projectName,
    handleAccountSelection,
    handleProjectSelection,
    handleChangeAccount,
  };
};
