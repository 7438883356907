import { DynamicContent, ProductRelease } from '@adsk/offsite-dc-sdk';
import { DATETIME_FORMAT, FlexContainer, ReleaseStatusWithColorBar } from '@mid-react-common/common';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { MetaInfo, MetaInfoPath, ProjectFolder } from 'mid-types';
import { KeyValueTableRow } from 'types/KeyValueTable';
import text from '../../../global/text.json';
import ReleaseStatusUpdate from '../ReleseStatusUpdate/ReleaseStatusUpdate';
import { IconWrapper } from './ReleaseDetails.styles';

const releasesText = text.releases;
const numberOfDefaultVisibleParameters = 4;

const CreateReleaseDetailsFolderPath: React.FC<{ releaseFullPath: string[] | Error }> = ({ releaseFullPath }) => {
  const theme = useTheme();
  const iconWidth = theme.var.releaseDetailsFolderIconWidth;
  if (releaseFullPath instanceof Error) {
    return <Typography color="error">{releaseFullPath.message}</Typography>;
  }
  return (
    <ul>
      {releaseFullPath.map((folder: string, index) => (
        <li key={`${folder}-${index}`}>
          <FlexContainer>
            {index > 1 && <Box width={iconWidth * (index - 1)} height={iconWidth}></Box>}
            <IconWrapper>
              {index !== 0 && <SubdirectoryArrowRightRoundedIcon color="disabled" />}
              <FolderOutlinedIcon />
            </IconWrapper>
            {folder}
          </FlexContainer>
        </li>
      ))}
    </ul>
  );
};

interface ReleaseInfoProps {
  currentRelease: ProductRelease;
  parameterVisibility: boolean;
  releaseFullPath: string[] | Error;
  handleShowAllClick: () => void;
  productReleases: ProductRelease[];
  setReloadReleaseData: (value: boolean) => void;
}

export const releaseInfo = ({
  currentRelease,
  parameterVisibility,
  releaseFullPath,
  handleShowAllClick,
  productReleases,
  setReloadReleaseData,
}: ReleaseInfoProps): KeyValueTableRow[] => [
  {
    label: releasesText.releaseDetailsParameters,
    value: (
      <ul>
        {currentRelease.inputs.map((input, index) => (
          <Box
            key={input.name}
            display={index > numberOfDefaultVisibleParameters - 1 && !parameterVisibility ? 'none' : 'block'}
          >
            <li>
              <>
                {input.label || input.name}{' '}
                {input.unit && (
                  <>
                    ({input.unit}){' '}
                    <Typography display="inline" color="text.secondary">
                      {input.value}
                    </Typography>
                  </>
                )}
              </>
            </li>
          </Box>
        ))}
        {currentRelease.inputs.length > numberOfDefaultVisibleParameters && (
          <li>
            <Button onClick={handleShowAllClick}>
              {parameterVisibility ? releasesText.showLess : releasesText.showAll}
            </Button>
          </li>
        )}
      </ul>
    ),
  },
  {
    label: releasesText.releasesTableColumnStatus,
    value: (
      <ul>
        <li>
          <FlexContainer>
            <ReleaseStatusWithColorBar status={currentRelease.status} />
          </FlexContainer>
        </li>
        <li>
          <ReleaseStatusUpdate
            productReleases={productReleases}
            releaseNumber={currentRelease.release}
            setReloadReleaseData={setReloadReleaseData}
            buttonText={releasesText.updateReleaseStatus}
            buttonVariant="text"
          />
        </li>
      </ul>
    ),
  },
  {
    label: releasesText.releasesTableColumnReleasedOn,
    value: format(Date.parse(currentRelease.createdAt), DATETIME_FORMAT),
  },
  {
    label: releasesText.releaseDetailsFolderPath,
    value: <CreateReleaseDetailsFolderPath releaseFullPath={releaseFullPath} />,
  },
];

export const getPublishLocationPathFromFolderPath = async (
  product: DynamicContent | ProductRelease,
): Promise<MetaInfoPath> => {
  const productFolderUrns = product.context.workspace.folderPath.split('/');
  const productFolders: MetaInfo[] = [];
  const promiseToRetrieveAllProjectFolders: Promise<ProjectFolder[]>[] = [];
  const forgeApiService = getForgeApiServiceInstance();

  for (let i = 0; i < productFolderUrns.length; i++) {
    if (i === 0) {
      // Initially, we want to retrieve all folders, not subfolders
      // So we don't pass a urn
      promiseToRetrieveAllProjectFolders.push(forgeApiService.getFolders({ projectId: product.tenancyId }));
    } else {
      // Moving on to subfolders
      promiseToRetrieveAllProjectFolders.push(
        forgeApiService.getSubFolders({ projectId: product.tenancyId, folderUrn: productFolderUrns[i - 1] }),
      );
    }
  }

  // Handling all promises at once
  const allProjectFolders: ProjectFolder[] = (await Promise.all(promiseToRetrieveAllProjectFolders)).reduce(
    (acc, next) => acc.concat(next),
    [],
  );

  // Extracting only the URNs we need
  productFolderUrns.forEach((urn) => {
    allProjectFolders.some((projectFolder) => {
      if (projectFolder.urn === urn) {
        // Pushing folders to start of the array to create the parentPath below
        productFolders.unshift({
          id: projectFolder.urn,
          name: projectFolder.title,
        });
      }
    });
  });

  // Creating MetaInfoPath object
  const folderPublishLocation: MetaInfoPath = {
    id: productFolders[0].id,
    name: productFolders[0].name,
    parentPath: Array.from(
      productFolders.slice(1), // removing folder product was published in
      (folder) => ({ id: folder.id, name: folder.name } as MetaInfo),
    ),
  };

  return folderPublishLocation;
};
