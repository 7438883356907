import Box from '@mui/material/Box';
import { css, styled } from '@mui/material/styles';

export const PageWrapper = styled(Box)`
  height: calc(100vh - ${({ theme }) => theme.var.midwMainHeader}px);
  padding: ${({ theme }) => theme.var.paddingBase * 5}px;
  overflow: hidden;
`;

export const ContentWrapper = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.var.paddingBase * 2}px;
  height: 100%;
  min-height: 500px;
  gap: ${({ theme }) => theme.var.paddingBase * 5}px;
`;

export const AddinBadge = styled(Box, {
  shouldForwardProp: (props) => props !== 'imageUrl',
})<{ imageUrl: string }>`
  flex: 1;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  ${(props) =>
    props.imageUrl &&
    css(`
      background-image: url(${props.imageUrl});
    `)}
  aspect-ratio: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ThumbnailsContainer = styled(Box)`
  position: relative;
  aspect-ratio: 1;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  min-width: 500px;
  padding-inline: 10%;
  top: 20%;
`;

export const LargeThumbnail = styled('img')`
  width: 100%;
`;

export const AllThumbnailsSection = styled(Box)`
  width: 100%;
  display: flex;
  gap: 5%;
  margin-top: 3%;
`;

export const SmallThumbnailWrapper = styled(Box)`
  flex: 1;
  cursor: pointer;
`;

export const SmallThumbnail = styled('img')`
  max-width: 100%;
`;

export const InformationWrapper = styled(Box)`
  flex: 1;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ButtonsWrapper = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.var.paddingBase * 2}px;
`;
