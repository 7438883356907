import { DownloadURLPayload, DownloadUrlResponse } from '@adsk/offsite-dc-sdk';
import { DEFAULT_POLLING_INTERVAL_IN_MS, NotificationContext, StateSetter } from '@mid-react-common/common';
import lodash from 'lodash';
import { getDcApiServiceInstance } from 'mid-api-services';
import { logError } from 'mid-utils';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useMatches, useNavigate } from 'react-router-dom';
import { getBase64Image } from 'utils/thumbnail';
import { FolderContentRow } from '../../components/ProductsPage/ModelsFolderContent/ModelsFolderContent.types';
import text from '../../global/text.json';
import { OpenModelURLParameter } from '../../types/common';
import { PollState, PollingConfig, UsePollingState } from './hooks.types';

export interface UseAsyncFetchData<DataType> {
  data: DataType | null;
  setData: StateSetter<DataType | null>;
  loading: boolean;
  error: Error | null;
}

export interface UseAsyncPostData<DataType> {
  loading: boolean;
  error: Error | null;
  postFn: (payload: any) => Promise<DataType | null>;
}

export function useAsyncFetchData<DataType>(apiCall: Function): UseAsyncFetchData<DataType> {
  const [data, setData] = useState<DataType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await apiCall();
      setData(result);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [apiCall]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    setData,
    loading,
    error,
  };
}

export function useAsyncFetchDataWithArgs<DataType>(apiCall: Function, args?: any[]): UseAsyncFetchData<DataType> {
  const [data, setData] = useState<DataType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(
    async (args: any) => {
      try {
        setLoading(true);
        const result = await apiCall(...args);
        setData(result);
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [apiCall],
  );

  useEffect(() => {
    if (args) {
      fetchData(args);
    } else {
      // We reset the data if the api call
      // args are not present
      setData(null);
    }
  }, [args, fetchData]);

  return {
    data,
    setData,
    loading,
    error,
  };
}

export function useAsyncPostData<DataType>(apiPostCall: Function): UseAsyncPostData<DataType> {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const postFn = async (payload: any): Promise<DataType | null> => {
    try {
      setLoading(true);
      const result = await apiPostCall(payload);
      setLoading(false);
      return result;
    } catch (error: any) {
      setLoading(false);
      setError(error);
      return null;
    }
  };

  return { loading, error, postFn };
}

export const useAsyncPollingWithArgs = <DataType>(
  callback: (...args: any) => Promise<DataType | undefined>,
  shouldPollingContinue: (data: DataType) => boolean,
  { interval }: PollingConfig = { interval: DEFAULT_POLLING_INTERVAL_IN_MS },
): UsePollingState<DataType> => {
  const [data, setData] = useState<DataType | null>(null);
  const [pollState, setPollState] = useState<PollState>(PollState.INDETERMINATE);

  // indicator for the polling function to stop due to unmount happend earlier than BE roundtrips from the polling
  // callback. It prevents the BUG of zombie-polling when user switches components very fast with clicking around.
  const finishedPolling = useRef<boolean>(false);

  // timerId has to be mutable and persisted for clearing upon unmounting
  const timerId = useRef<number>();

  const startPolling = (): void => {
    setPollState(PollState.READY);
  };

  useEffect(() => {
    // cache the previous result to allow the "user" component network calls optimization
    let prevResult: DataType | undefined;

    async function poll() {
      try {
        if (finishedPolling.current) {
          return;
        }

        setPollState(PollState.POLLING);
        const result = await callback(prevResult);

        // performance optimization, to prevent multiple state changes in case result is the same
        // it reduces the number of parent component rerenderings
        const sameResult = lodash.isEqual(result, prevResult);

        prevResult = result;

        if (result && !sameResult) {
          setData(result);
          if (!shouldPollingContinue(result)) {
            setPollState(PollState.FINISHED);
            return;
          }
        }

        timerId.current = window.setTimeout(poll, interval);
      } catch (error: any) {
        logError(error);
        setPollState(PollState.ERROR);
      }
    }
    // TODO: save the Variant to the dataStore?
    if (pollState === PollState.READY) {
      finishedPolling.current = false;
      poll();
    }

    return () => {
      if (pollState === PollState.POLLING) {
        finishedPolling.current = true;
      }

      if (timerId.current) {
        timerId.current && clearTimeout(timerId.current);
        timerId.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollState, interval]);

  return {
    data,
    pollState,
    startPolling,
  };
};

export const usePrevious = (value: any): any => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
};

export const useDefaultNavigation = (routeFromId: string, routeToId: string): void => {
  const location = useLocation();
  const navigate = useNavigate();
  const routes = useMatches();

  const foundRoute = routes.find((route) => route.id === routeFromId);

  useEffect(() => {
    // check if the current location exactly corresponds to the 'routeFromId'
    // if so, navigate to the provided 'routeToId' route
    if (foundRoute && foundRoute.pathname === location.pathname) {
      navigate(routeToId, {
        replace: true,
      });
    }
  }, [navigate, foundRoute, location.pathname, routeToId]);
};

export type UseNavigationRoutinesState = {
  parseOpenModelURLParameter: (openModelURLParameter: string) => OpenModelURLParameter;
  serializeOpenModelDataAsURLParameter: (currentlyOpenModel: FolderContentRow, selectedFolderUrn: string) => string;
};
export const useNavigationRoutines = (): UseNavigationRoutinesState => {
  const serializeOpenModelDataAsURLParameter = useCallback(
    (currentlyOpenModel: FolderContentRow, selectedFolderUrn: string) => {
      const openModelURLParameter: OpenModelURLParameter = {
        lmvModelFileId: currentlyOpenModel.lmvModelFileId,
        folderUrn: selectedFolderUrn,
        itemUrn: currentlyOpenModel.id,
      };

      return window.btoa(JSON.stringify(openModelURLParameter));
    },
    [],
  );

  // memoize the function since it's typically used in useEffect of the consumer components
  const parseOpenModelURLParameter = useCallback(
    (openModelURLParameter: string): OpenModelURLParameter => JSON.parse(window.atob(openModelURLParameter)),
    [],
  );

  return {
    parseOpenModelURLParameter,
    serializeOpenModelDataAsURLParameter,
  };
};

interface UseThumbnailState {
  thumbnail: string | null;
  isThumbnailLoading: boolean;
}
interface UseThumbnailProps {
  thumbnailId: string | undefined;
  thumbnailProjectId: string | undefined;
}
export function useThumbnail({ thumbnailId, thumbnailProjectId }: UseThumbnailProps): UseThumbnailState {
  const { logAndShowNotification } = useContext(NotificationContext);
  const [isThumbnailLoading, setIsThumbnailLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  // Cache locally the thumbnails
  const fetchedThumbnails = useMemo(() => new Map<string, string>(), []);

  const fetchThumbnail = useCallback(async () => {
    setIsThumbnailLoading(true);
    if (!thumbnailId || !thumbnailProjectId) {
      setIsThumbnailLoading(false);
      return;
    }
    // Check if the thumbnail is already downloaded
    if (fetchedThumbnails.has(thumbnailId)) {
      setThumbnail(fetchedThumbnails.get(thumbnailId) || null);
      setIsThumbnailLoading(false);
    } else {
      // Otherwise, download the thumbnail
      try {
        const downloadURLPayload: DownloadURLPayload = {
          objectKey: thumbnailId,
        };
        const downloadUrlResponse: DownloadUrlResponse = await getDcApiServiceInstance().downloadURL(
          thumbnailProjectId,
          downloadURLPayload,
        );
        if (downloadUrlResponse.signedUrl) {
          const base64Image = await getBase64Image(downloadUrlResponse.signedUrl);
          fetchedThumbnails.set(thumbnailId, base64Image);
          setThumbnail(base64Image);
        }
      } catch (error) {
        logAndShowNotification({
          message: text.useThumbnail.thumbnailLoadError,
        });
      } finally {
        setIsThumbnailLoading(false);
      }
    }
  }, [thumbnailId, thumbnailProjectId, fetchedThumbnails, logAndShowNotification]);

  useEffect(() => {
    if (thumbnailId) {
      fetchThumbnail();
    } else {
      setIsThumbnailLoading(false);
    }
  }, [thumbnailId, fetchThumbnail]);

  return { thumbnail, isThumbnailLoading };
}
