import { AddinList } from 'mid-types';
import BadgeSection from './Components/BadgeSection';
import DownloadSection from './Components/DownloadSection';
import { ContentWrapper, PageWrapper } from './Installers.styles';
import { useInstallers } from './useInstallers';

interface InstallersProps {
  badge: string;
  allThumbnails: { description: string; image: string }[];
  title: string;
  information: string;
  productPageUrl: string;
  productName: AddinList;
}

const Installers: React.FC<InstallersProps> = ({
  badge,
  allThumbnails,
  title,
  information,
  productPageUrl,
  productName,
}) => {
  const { addinVersionInfoByName } = useInstallers();
  return (
    <PageWrapper>
      <ContentWrapper>
        <BadgeSection badge={badge} allThumbnails={allThumbnails} />
        {addinVersionInfoByName && (
          <DownloadSection
            title={title}
            information={information}
            addinUrl={addinVersionInfoByName[productName]}
            productPageUrl={productPageUrl}
            productName={productName}
          />
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Installers;
