import { Vendors } from '@adsk/offsite-dc-sdk';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import text from '../../../../../global/text.json';
import { UploadLocationIds } from '../../../../../tests/helpers/dataTestIds';
import { ModalActionContainer, ModalButton, TooltipContainer } from './OutputFolderButtonAndModal.styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { IconButton } from '@mui/material';
import { AUTODESK_DOCS_PERMISSION_LINK, FUSION_PERMISSION_LINK } from 'global/constants/links';

type ModalActionsProps = {
  handleSubfolderCreationDisplay: () => void;
  handleCancellation: () => void;
  handleConfirmation: () => void;
  isFolderActionDisabled: boolean;
  vendor?: Vendors;
};

const uploadLocationText = text.uploadLocation;

export const ModalActions: React.FC<ModalActionsProps> = ({
  handleSubfolderCreationDisplay,
  handleCancellation,
  handleConfirmation,
  isFolderActionDisabled,
  vendor,
}) => {
  const { enableFusionExport } = useFlags();

  return (
    <ModalActionContainer>
      {enableFusionExport ? (
        <Box display="flex" alignItems="center">
          <Button
            variant="outlined"
            startIcon={<CreateNewFolderOutlinedIcon />}
            onClick={handleSubfolderCreationDisplay}
            disabled={isFolderActionDisabled}
            data-testid={UploadLocationIds.addSubFolderButton}
          >
            {uploadLocationText.addSubfolder}
          </Button>
          {vendor && (
            <Tooltip
              title={
                <TooltipContainer key={vendor}>
                  <Typography variant="body2">{uploadLocationText.permissionTooltip}</Typography>
                  <Link
                    href={vendor === Vendors.FUSIONTEAM ? FUSION_PERMISSION_LINK : AUTODESK_DOCS_PERMISSION_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography variant="body2">
                      {vendor === Vendors.FUSIONTEAM
                        ? uploadLocationText.projectSecurityInFusion
                        : uploadLocationText.folderPermissionsInAutodeskDocs}
                    </Typography>
                  </Link>
                </TooltipContainer>
              }
              placement="top"
              arrow
              disableFocusListener={!isFolderActionDisabled}
              disableHoverListener={!isFolderActionDisabled}
            >
              <IconButton
                disableRipple
                disableFocusRipple
                disabled={!isFolderActionDisabled}
                data-testid={UploadLocationIds.tooltipIconButton}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ) : (
        /** Empty div is used to satisfy space-between flex style
         * This will be removed once export fusion feature is completed
         */
        <div />
      )}
      <div>
        <Button variant="outlined" onClick={handleCancellation}>
          {uploadLocationText.cancel}
        </Button>
        <ModalButton
          variant="contained"
          onClick={handleConfirmation}
          disabled={isFolderActionDisabled}
          data-testid={UploadLocationIds.selectButton}
        >
          {uploadLocationText.select}
        </ModalButton>
      </div>
    </ModalActionContainer>
  );
};
