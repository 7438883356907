import { Vendors } from '@adsk/offsite-dc-sdk';
import { NotificationContext, StateSetter, useCancellablePromise } from '@mid-react-common/common';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { HubProjectFolderResponse, contentTypes, folderPermissionActions } from 'mid-types';
import { BIM360FoldersFetchError, HubProjectFolderFetchError } from 'mid-utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Tree, TreeNode } from 'types/common';
import { flattenFolderTree } from './FoldersTree.utils';
import { improveErrorDetailFromSubFolderCreationRequest } from './useFoldersTree.utils';

export interface UseFoldersTreeProps {
  accountId: string | null;
  projectId: string | null;
  foldersTree: Tree | undefined;
  isSubfolderCreationInputVisible?: boolean;
  setFoldersTree: StateSetter<Tree | undefined>;
  setExpandedTreeNodeIds: StateSetter<string[]>;
  folderUrn: string | undefined;
  selectFolder: (folderUrn: string) => void;
  vendor?: Vendors;
  setEditPermissionLookup?: StateSetter<Record<string, boolean>>;
}

export const rootNodeErrorReasons = {
  ROOT: 'root',
  NOT_ROOT: 'not root',
};

export interface UseFoldersTreeState {
  error: { error: Error; reason: any } | null;
  rootNodes: TreeNode[] | undefined;
  hasEmptyRootNodes: boolean;
  handleFolderToggle: (_event: React.SyntheticEvent, nodeIds: string[]) => Promise<void>;
  handleSubFolderCreation: (folderName: string) => Promise<boolean>;
  subFolderCreationError?: string;
  subFolderCreationRequestLoading?: boolean;
  resetSubFolderCreationError?: () => void;
}

const useFoldersTree = ({
  accountId,
  projectId,
  foldersTree,
  folderUrn = '',
  vendor,
  isSubfolderCreationInputVisible,
  setFoldersTree,
  setExpandedTreeNodeIds,
  selectFolder,
  setEditPermissionLookup,
}: UseFoldersTreeProps): UseFoldersTreeState => {
  const { logAndShowNotification } = useContext(NotificationContext);
  const [rootNodes, setRootNodes] = useState<TreeNode[] | undefined>(undefined);
  const [error, setError] = useState<{ error: Error; reason: any } | null>(null);
  const [subFolderCreationError, setSubFolderCreationError] = useState<string | undefined>(undefined);
  const [subFolderCreationRequestLoading, setSubFolderCreationRequestLoading] = useState<boolean | undefined>(false);

  const forgeApiService = getForgeApiServiceInstance();
  const cancellablePromise = useCancellablePromise();

  useEffect(() => {
    // The subFolderCreationError needs to be reset when the user
    // changes the folder selection context (determined by props in dependency array)
    resetSubFolderCreationState();
  }, [accountId, projectId, foldersTree, folderUrn, isSubfolderCreationInputVisible]);

  const getPermissionsForFolders = useCallback(
    async (projectId: string, folderNodes: TreeNode[]): Promise<Record<string, boolean>> => {
      if (folderNodes.length === 0) {
        return {};
      }

      const permissionResult = await cancellablePromise(
        forgeApiService.checkHubProjectFolderPermissions({
          projectId,
          vendor,
          folderUrns: folderNodes.map(({ id }) => id),
          permissionActions: [folderPermissionActions.CREATE, folderPermissionActions.UPLOAD],
        }),
      );

      return permissionResult.attributes.extension.data.permissions.reduce<Record<string, boolean>>(
        (permissions, currentPermission) => ({ ...permissions, [currentPermission.id]: currentPermission.permission }),
        {},
      );
    },
    [vendor, forgeApiService, cancellablePromise],
  );

  const getFolderTreeNodes = useCallback(
    async (projectId: string, folderUrn: string): Promise<{ result: Record<string, TreeNode[]>; expandedPath: string }> => {
      const folderTree = await cancellablePromise(forgeApiService.getFolderTree(projectId, folderUrn));

      return flattenFolderTree(folderTree, folderUrn);
    },
    [forgeApiService, cancellablePromise],
  );

  const getRootFolders = useCallback(
    async (accountId: string, projectId: string): Promise<TreeNode[]> =>
      (await cancellablePromise(forgeApiService.getHubProjectTopFolders({ hubId: accountId, projectId, vendor })))
        .map((folder) => ({
          id: folder.id,
          label: folder.attributes.displayName,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    [forgeApiService, cancellablePromise, vendor],
  );

  const getSubfolders = useCallback(
    async (projectId: string, folderId: string): Promise<TreeNode[]> => {
      const folderContents = await cancellablePromise(
        forgeApiService.getHubProjectFolderContents({ projectId, folderId, vendor }),
      );

      return folderContents.reduce<TreeNode[]>(
        (acc, currentContent) =>
          currentContent.type === contentTypes.FOLDERS
            ? [...acc, { id: currentContent.id, label: currentContent.attributes.displayName }]
            : acc,
        [],
      );
    },
    [vendor, forgeApiService, cancellablePromise],
  );

  const getRootNodesCallback = useCallback(async () => {
    setError(null);
    setRootNodes(undefined);

    if (!accountId || !projectId || foldersTree !== undefined) {
      return;
    }

    // if the folderUrn is provided, it means that the whole tree related to this folder needs to be loaded
    if (folderUrn) {
      try {
        const folderNodes = await getFolderTreeNodes(projectId, folderUrn);

        setRootNodes(folderNodes.result.root);
        setFoldersTree(folderNodes.result);

        // extract the env-dependent prefix of the folderUrn (URN)
        const folderUrnPrefix = folderUrn.match(/.*\./)![0];
        setExpandedTreeNodeIds(folderNodes.expandedPath.split('/').map((item) => folderUrnPrefix + item));

        selectFolder(folderUrn);
      } catch (error) {
        logAndShowNotification({ error });
        if (error instanceof BIM360FoldersFetchError) {
          setError({ error, reason: rootNodeErrorReasons.NOT_ROOT });
        }
      }

      return;
    }

    try {
      const rootFolders = await getRootFolders(accountId, projectId);
      const editPermissions = await getPermissionsForFolders(projectId, rootFolders);
      if (setEditPermissionLookup) {
        setEditPermissionLookup(editPermissions);
      }

      setRootNodes(rootFolders);
      setFoldersTree({ root: rootFolders });
    } catch (error) {
      logAndShowNotification({ error });
      if (error instanceof HubProjectFolderFetchError) {
        setError({ error, reason: rootNodeErrorReasons.ROOT });
      }
    }
  }, [
    folderUrn,
    foldersTree,
    accountId,
    projectId,
    getFolderTreeNodes,
    getRootFolders,
    logAndShowNotification,
    selectFolder,
    setExpandedTreeNodeIds,
    setFoldersTree,
    getPermissionsForFolders,
    setEditPermissionLookup,
  ]);

  useEffect(() => {
    getRootNodesCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, projectId]);

  const handleFolderToggle = useCallback(
    async (_event: React.SyntheticEvent, nodeIds: string[]) => {
      setExpandedTreeNodeIds(nodeIds);
      if (!projectId || !nodeIds.length) {
        return;
      }

      const folderId = nodeIds[0];
      try {
        const subfolders = await getSubfolders(projectId, folderId);
        const editPermissions = await getPermissionsForFolders(projectId, subfolders);
        if (setEditPermissionLookup) {
          setEditPermissionLookup((previousPermissions) => ({ ...previousPermissions, ...editPermissions }));
        }

        const newTree: Tree = {
          ...foldersTree,
          [folderId]: subfolders,
        };

        setFoldersTree(newTree);
      } catch (error) {
        logAndShowNotification({ error });
      }
    },
    [
      projectId,
      foldersTree,
      getSubfolders,
      logAndShowNotification,
      setFoldersTree,
      setExpandedTreeNodeIds,
      getPermissionsForFolders,
      setEditPermissionLookup,
    ],
  );

  const handleSubFolderCreation = useCallback(
    async (folderName: string): Promise<boolean> => {
      if (!projectId || !folderUrn || !foldersTree || !vendor) {
        return false;
      }
      setSubFolderCreationError(undefined);
      setSubFolderCreationRequestLoading(true);
      const { data, errors }: HubProjectFolderResponse = await forgeApiService.createHubProjectSubfolder(
        projectId,
        folderUrn,
        folderName,
        vendor,
      );

      if (data) {
        const newSubFolder = data;
        const newTree: Tree = {
          ...foldersTree,
          [folderUrn]: [{ id: newSubFolder.id, label: newSubFolder.attributes.displayName }, ...foldersTree[folderUrn]],
          [newSubFolder.id]: [],
        };
        if (setEditPermissionLookup) {
          setEditPermissionLookup((previousPermissions) => ({ ...previousPermissions, [newSubFolder.id]: true }));
        }
        setFoldersTree(newTree);
        selectFolder(newSubFolder.id);
        setSubFolderCreationError(undefined);
        setSubFolderCreationRequestLoading(false);
        return true;
      }

      if (errors && errors.length) {
        // we always show the first error message
        // as the api returns one error at a time even
        // if there are multiple errors.
        // Tested with duplicate name & restricted characters in name together
        const improvedErrorDetail = improveErrorDetailFromSubFolderCreationRequest(errors[0].status, errors[0].detail);
        setSubFolderCreationError(improvedErrorDetail);
        setSubFolderCreationRequestLoading(false);
      }

      return false;
    },
    [projectId, folderUrn, vendor, forgeApiService, foldersTree, setFoldersTree, selectFolder, setEditPermissionLookup],
  );

  const resetSubFolderCreationState = () => {
    setSubFolderCreationError(undefined);
    setSubFolderCreationRequestLoading(undefined);
  };

  const resetSubFolderCreationError = () => {
    setSubFolderCreationError(undefined);
  };

  return {
    error,
    rootNodes,
    hasEmptyRootNodes: Array.isArray(rootNodes) && rootNodes.length === 0,
    handleFolderToggle,
    handleSubFolderCreation,
    subFolderCreationError,
    subFolderCreationRequestLoading,
    resetSubFolderCreationError,
  };
};

export default useFoldersTree;
