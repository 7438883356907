import Installers from 'components/Installers/Installers';
import {
  allRevitThumbnails,
  informedDesignRevitProductPageUrl,
  revitInstallersBadge,
} from 'components/Installers/constants';
import { Addins } from 'mid-types';
import text from '../../global/text.json';

const RevitInstallersPage: React.FC = () => (
  <Installers
    badge={revitInstallersBadge}
    allThumbnails={allRevitThumbnails}
    title={text.installers.revit.title}
    information={text.installers.revit.information}
    productPageUrl={informedDesignRevitProductPageUrl}
    productName={Addins.REVIT}
  />
);

export default RevitInstallersPage;
