import { GridColDef } from '@weave-mui/data-grid';
import text from '../../global/text.json';

export const MIDModelParameterPrefix = 'MID';
export const MIDModelContentIdParameterName = `${MIDModelParameterPrefix}/contentId`;
export const MIDModelVariantIdParameterName = `${MIDModelParameterPrefix}/variantId`;
export const MIDModelProjectIdParameterName = `${MIDModelParameterPrefix}/tenancyId`;
export const MIDModelReleaseParameterName = `${MIDModelParameterPrefix}/release`;

export const dataGridDefaultSettings = {
  ROW_HEIGHT: 34,
  HEADER_HEIGHT: 34,
  ROWS_PER_PAGE: [25, 50, 100],
  COLUMN_WIDTH: 200,
};

const productInstancesText = text.productInstancesDataGrid;
export const defaultColumsForVariantInputs: GridColDef[] = [
  {
    field: 'elementId',
    headerName: productInstancesText.ID,
    width: 100,
  },
  {
    field: 'variantName',
    headerName: productInstancesText.variant,
    width: 200,
  },
];

export const useVariantCanvasConstants = {
  ATTRIBUTE_NAME: 'crossOrigin',
  ATTRIBUTE_VALUE: 'anonymous',
  ELEMENT: 'canvas',
  CONTEXT: '2d',
  IMAGE_TYPE: 'image/png',
};
