import { Vendors } from '@adsk/offsite-dc-sdk';
import { AccProjectPlatform, accProjectPlatform } from 'mid-types';

export const BIM360CurrentVersionProcessState = {
  PROCESSING_COMPLETE: 'PROCESSING_COMPLETE',
  EXTRACTION_PENDING: 'EXTRACTION_PENDING',
};

export const BIM360CurrentVersionProcessResult = {
  PROCESSING_SUCCESS: 'PROCESSING_SUCCESS',
};

export const vendorPlatforms: Record<AccProjectPlatform, Vendors> = {
  [accProjectPlatform.acc]: Vendors.ACC,
  [accProjectPlatform.bim360]: Vendors.BIM360,
};
