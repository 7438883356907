import { DCInput, Variant, VariantInput } from '@adsk/offsite-dc-sdk';
import { StateSetter } from '@mid-react-common/common';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef, GridValueGetterParams } from '@weave-mui/data-grid';
import { isNull, isUndefined } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import ProductContext from '../../../../context/ProductStore/Product.context';
import { dataGridDefaultSettings, defaultColumsForVariantInputs } from '../../../../global/constants/products';
import text from '../../../../global/text.json';
import { VariantsMap } from '../../../../types/variants';

interface UseInstancePanelUIState {
  expanded: boolean;
  handleToggleExpanded: () => void;
  showInstanceDetailsPanel: boolean;
  setShowInstanceDetailsPanel: StateSetter<boolean>;
  dataGridColumns: GridColDef[];
}

const useInstancePanelUI = (): UseInstancePanelUIState => {
  const { variants, selectedProductRelease, dataGridInstances } = useContext(ProductContext);

  const [expanded, setExpanded] = useState(false);
  const [showInstanceDetailsPanel, setShowInstanceDetailsPanel] = useState<boolean>(false);
  const [dataGridColumns, setDataGridColumns] = useState<GridColDef[]>([]);

  const handleToggleExpanded = () => {
    setExpanded(!expanded);
  };

  const getInstancesGridColumns = useCallback(() => {
    const variantsMap: VariantsMap = variants.reduce((acc: VariantsMap, curr: Variant) => {
      if (
        selectedProductRelease &&
        curr.contentId === selectedProductRelease.contentId &&
        curr.release === selectedProductRelease.release
      ) {
        acc[curr.variantId] = curr;
      }
      return acc;
    }, {});
    let columnsForVariantInputs: GridColDef[] = [];

    if (selectedProductRelease) {
      // All variants belonging to the same product release
      // will always have the same inputs (not values)
      // as the inputs for Variants are merged with the Product inputs
      // on the back-end
      const inputs = selectedProductRelease.inputs || [];
      columnsForVariantInputs = inputs.map((colInput: DCInput) => ({
        field: colInput.name,
        headerName: colInput.name,
        width: dataGridDefaultSettings.COLUMN_WIDTH,
        valueGetter: (params: GridValueGetterParams) => {
          if (params.row) {
            const associatedVariant: Variant | undefined = variantsMap[params.row.variantId];
            const matchingInput: VariantInput | undefined = associatedVariant?.inputs.find(
              (rowInput: VariantInput) => rowInput.name === colInput.name,
            );
            if (matchingInput) {
              return isUndefined(matchingInput.value) || isNull(matchingInput.value)
                ? text.common.noValueAvailable
                : matchingInput.value;
            }
            return text.common.noValueAvailable;
          }
        },
      }));
    }

    const columns: GridColDef[] = [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        type: 'boolean',
        width: 60,
      },
      ...defaultColumsForVariantInputs,
      ...columnsForVariantInputs,
    ];

    setDataGridColumns(columns);
  }, [selectedProductRelease, setDataGridColumns, variants]);

  useEffect(() => {
    if (dataGridInstances.length > 0) {
      getInstancesGridColumns();
    }
  }, [dataGridInstances.length, getInstancesGridColumns]);

  return {
    expanded,
    handleToggleExpanded,
    showInstanceDetailsPanel,
    setShowInstanceDetailsPanel,
    dataGridColumns,
  };
};

export default useInstancePanelUI;
