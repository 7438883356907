import { ReactNode } from 'react';
import { GridColDef, GridRenderCellParams } from '@weave-mui/data-grid';
import text from '../../../global/text.json';
import StatusOutputsCell from './StatusOutputsCell';
import { OutputType } from '@adsk/offsite-dc-sdk';
import InstancesResultDetails from './InstancesResultDetails/InstancesResultDetails';

const reviewPanelText = text.reviewPanel;

const renderFileType = (params: GridRenderCellParams): ReactNode => {
  switch (params.row.fileType) {
    case OutputType.BOM:
      return `.${reviewPanelText.csv}`;
    default:
      return `.${params.row.fileType.toLowerCase()}`;
  }
};

export const columns: GridColDef[] = [
  {
    field: 'status',
    headerName: reviewPanelText.status,
    width: 150,
    renderCell: StatusOutputsCell,
  },
  {
    field: 'fileType',
    headerName: reviewPanelText.fileType,
    width: 100,
    renderCell: renderFileType,
  },
  {
    field: 'name',
    headerName: reviewPanelText.variantName,
    width: 300,
  },
  {
    field: 'productName',
    headerName: reviewPanelText.productName,
    width: 300,
    renderCell: (params: GridRenderCellParams) => `${params.row.productName} (${params.row.release})`,
  },
  {
    field: 'modelState',
    headerName: reviewPanelText.representation,
    width: 300,
  },
  {
    field: 'revitId',
    headerName: reviewPanelText.revitId,
    width: 150,
    renderCell: InstancesResultDetails,
  },
  {
    field: 'modifiedAt',
    headerName: reviewPanelText.updatedOn,
    width: 200,
  },
];
