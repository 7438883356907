import Installers from 'components/Installers/Installers';
import {
  allInventorThumbnails,
  informedDesignInventorProductPageUrl,
  inventorInstallersBadge,
} from 'components/Installers/constants';
import { Addins } from 'mid-types';
import text from '../../global/text.json';

const InventorInstallersPage: React.FC = () => (
  <Installers
    badge={inventorInstallersBadge}
    allThumbnails={allInventorThumbnails}
    title={text.installers.inventor.title}
    information={text.installers.inventor.information}
    productPageUrl={informedDesignInventorProductPageUrl}
    productName={Addins.INVENTOR}
  />
);

export default InventorInstallersPage;
