import InventorAddinBadge from '../../images/InstallersPages/Inventor/autodesk-informed-design-for-inventor-badge-2048.jpg';
import ConstraintsImage from '../../images/InstallersPages/Inventor/inventor - image 1 - author constraints rules.jpg';
import ManageVersionsImage from '../../images/InstallersPages/Inventor/inventor - image 2 - manage wip versions.jpg';
import PreviewRulesImage from '../../images/InstallersPages/Inventor/inventor - image 3 - preview and test rules.jpg';
import OutputsImage from '../../images/InstallersPages/Inventor/inventor - image 4 - specify output type availability.jpg';

import RevitAddinBadge from '../../images/InstallersPages/Revit/autodesk-informed-design-for-revit-badge-2048.jpg';
import RulesCorrectingImage from '../../images/InstallersPages/Revit/revit - image 1 - rules correcting.jpg';
import BrowseImage from '../../images/InstallersPages/Revit/revit - image 2 - browse.jpg';
import ExistingVariantsImage from '../../images/InstallersPages/Revit/revit - image 3 - existing variants.jpg';
import EditImage from '../../images/InstallersPages/Revit/revit - image 4 - edit.jpg';

import text from '../../global/text.json';

const inventorInstallersText = text.installers.inventor;
export const informedDesignInventorProductPageUrl = 'https://www.autodesk.com/products/informed-design-inventor';
export const inventorInstallersBadge = InventorAddinBadge;
export const allInventorThumbnails = [
  { description: inventorInstallersText.constraintsThumbnailDescription, image: ConstraintsImage },
  { description: inventorInstallersText.manageVersionsThumbnailDescription, image: ManageVersionsImage },
  { description: inventorInstallersText.previewRulesThumbnailDescription, image: PreviewRulesImage },
  { description: inventorInstallersText.outputsThumbnailDescription, image: OutputsImage },
];

const revitInstallersText = text.installers.revit;
export const informedDesignRevitProductPageUrl = 'https://www.autodesk.com/products/informed-design-revit';
export const revitInstallersBadge = RevitAddinBadge;
export const allRevitThumbnails = [
  { description: revitInstallersText.rulesCorrectingThumbnail, image: RulesCorrectingImage },
  { description: revitInstallersText.browseImage, image: BrowseImage },
  { description: revitInstallersText.existingVariantsImage, image: ExistingVariantsImage },
  { description: revitInstallersText.editImage, image: EditImage },
];
