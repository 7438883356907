import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

export const DetailsSidebarWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
  transition: width 0.3s linear;
`;

export const DetailsSidebarHeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DetailsSidebarContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-width: ${({ theme }) => `${theme.var.detailsSidebarMinWidth}px`};
`;

export const DetailsSidebarDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.var.marginBase}px`} 0;
`;
