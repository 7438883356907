import { ProductRelease, VariantInput } from '@adsk/offsite-dc-sdk';
import { Instance } from '../../../types/product';
import { KeyValueTableRow } from '../../../types/KeyValueTable';
import text from '../../../global/text.json';
import { isNumericVariantInput, isTextVariantInput } from 'mid-utils';
const instanceDetailsText = text.instanceDetailsPanel;

export const getProductInfo = (
  currentProductRelease: ProductRelease | undefined,
  currentInstance: Instance | null,
): KeyValueTableRow[] => [
  {
    label: instanceDetailsText.iamFile,
    value: currentProductRelease?.context.topLevelAssembly,
  },
  { label: instanceDetailsText.category, value: currentInstance?.category },
  {
    label: instanceDetailsText.familyType,
    value: currentInstance?.familyType,
  },
  {
    label: instanceDetailsText.elementID,
    value: currentInstance?.elementId,
  },
  { label: instanceDetailsText.product, value: currentProductRelease?.name },
];

export const transformVariantInputsIntoTableRows = (inputs: VariantInput[]): KeyValueTableRow[] =>
  inputs.map((input) => {
    const label =
      isNumericVariantInput(input) || isTextVariantInput(input) ? `${input.name} (${input.unit})` : `${input.name}`;
    return {
      label,
      value: input.value.toString() || '',
    };
  });
