import { DynamicContent, ProductRelease } from '@adsk/offsite-dc-sdk';
import { DATETIME_FORMAT, useCancellablePromise } from '@mid-react-common/common';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridRowId, GridRowModel, GridRowSelectionModel, useGridApiRef } from '@weave-mui/data-grid';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { getDcApiServiceInstance } from 'mid-api-services';
import { useContext, useEffect, useState } from 'react';
import AccountProjectContext from '../../context/AccountProjectStore/AccountProject.context';

interface UseReleasesState {
  tableLoading: boolean;
  productsLoading: boolean;
  productReleases: ProductRelease[];
  releaseTableData: GridRowModel[];
  productListFiltered: DynamicContent[];
  selectedProduct: DynamicContent | null;
  gridApiRef: React.MutableRefObject<GridApiPremium>;
  detailsVisibility: boolean;
  selectedReleasesIds: GridRowId[];
  handleFilter: (event: React.FormEvent<HTMLInputElement>) => void;
  handleProductSelection: (product: DynamicContent) => void;
  handleDetailsVisibilityClick: () => void;
  onReleaseSelection: (selectedReleases: GridRowSelectionModel) => void;
  handleCloseDetails: () => void;
  setReloadReleaseData: (reload: boolean) => void;
}

const useReleases = (): UseReleasesState => {
  const gridApiRef = useGridApiRef();
  const { projectId } = useContext(AccountProjectContext);
  const [selectedProduct, setSelectedProduct] = useState<DynamicContent | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [productList, setProductList] = useState<DynamicContent[]>([]);
  const [productListFiltered, setProductListFiltered] = useState<DynamicContent[]>([]);
  const [productReleases, setProductReleases] = useState<ProductRelease[]>([]);
  const [productsLoading, setProductsLoading] = useState<boolean>(true);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [detailsVisibility, setDetailsVisibility] = useState<boolean>(false);
  const [selectedReleasesIds, setSelectedReleasesIds] = useState<GridRowId[]>([]);
  const [reloadReleaseData, setReloadReleaseData] = useState(true);
  const dcApiService = getDcApiServiceInstance();
  const cancellablePromise = useCancellablePromise();

  const handleProductSelection = (product: DynamicContent) => {
    if (product.contentId === selectedProduct?.contentId) {
      return;
    }
    setTableLoading(true);
    setSelectedProduct(product);
  };

  const handleFilter = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchQuery((event.target as HTMLInputElement).value);
  };

  const debouncedHandleFilter = debounce(handleFilter, 500);

  const filterData = (query: string, products: DynamicContent[]) => {
    if (!query) {
      return products;
    }
    return products.filter((product) => product.name.toLowerCase().includes(query.toLowerCase()));
  };

  const handleDetailsVisibilityClick = () => {
    setDetailsVisibility((visibility) => !visibility);
  };

  const onReleaseSelection = (selectedReleases: GridRowSelectionModel) => {
    setDetailsVisibility(true);
    setSelectedReleasesIds(selectedReleases.map((release) => release));
  };

  const handleCloseDetails = () => {
    setDetailsVisibility(false);
  };

  useEffect(() => {
    setProductListFiltered(filterData(searchQuery, productList));
  }, [productList, searchQuery]);

  // Fetch list of products on page loads
  useEffect(() => {
    const fetchProductsList = async () => {
      if (projectId) {
        const productList = await cancellablePromise(dcApiService.getProductsList(projectId));
        setProductList(productList);
        setProductsLoading(false);
      }
    };
    fetchProductsList();
  }, [dcApiService, projectId, cancellablePromise]);

  // Fetch all releases on product selection
  useEffect(() => {
    const fetchProductReleases = async (product: DynamicContent) => {
      if (projectId) {
        try {
          const releases = await cancellablePromise(dcApiService.getProductReleasesList(projectId, product.contentId));
          setProductReleases(releases);
          setTableLoading(false);
        } catch (e) {
          setProductReleases([]);
          setTableLoading(false);
        }
      }
    };
    if (selectedProduct) {
      setTableLoading(true);
      setReloadReleaseData(false);
      fetchProductReleases(selectedProduct);
    }
  }, [dcApiService, selectedProduct, projectId, reloadReleaseData, setReloadReleaseData, cancellablePromise]);

  // Generate table rows
  const generateReleaseTableRows = (productReleases: ProductRelease[]) => {
    const productReleasesTableData = productReleases
      .map((release) => {
        const fullPath = release.context.workspace.folderPath.split('/');
        const folderUrn = fullPath[fullPath.length - 1];
        return {
          id: release.release,
          releaseNumber: release.release,
          releasedOn: format(Date.parse(release.createdAt), DATETIME_FORMAT),
          status: release.status,
          folder: {
            tenancyId: release.tenancyId,
            contentId: release.contentId,
            folderUrn,
          },
        };
      })
      .sort((a, b) => a.releaseNumber - b.releaseNumber);
    return productReleasesTableData;
  };

  const releaseTableData: GridRowModel[] = generateReleaseTableRows(productReleases);

  return {
    gridApiRef,
    tableLoading,
    productsLoading,
    productListFiltered,
    productReleases,
    releaseTableData,
    selectedProduct,
    handleFilter: debouncedHandleFilter,
    detailsVisibility,
    selectedReleasesIds,
    handleProductSelection,
    handleDetailsVisibilityClick,
    handleCloseDetails,
    onReleaseSelection,
    setReloadReleaseData,
  };
};

export default useReleases;
