import text from '../../global/text.json';
export const sidebarNavTestIds = {
  sidebarItemButton: 'sidebar-item-button',
  sidebarItemText: 'sidebar-item-text',
  showHideSidebar: 'show-hide-sidebar',
};

export const mainApp = {
  productsNav: 'products-nav',
  outputsNav: 'outputs-nav',
  releasesNav: 'releases-nav',
};

export const header = {
  logo: 'logo',
};

export const modelFolderContentIds = {
  modelsFolderContentSearch: 'models-folder-content-search',
};

export const modelDetails = {
  modelDetailsOpenButton: 'model-details-open-button',
  generateOutputsButton: 'generate-outputs-button',
};

export const outputsSettingsIds = {
  assignButton: 'output-settings-assign-button',
  selectProductPrompt: 'select-product-prompt',
  selectAllOutputs: 'select-all-outputs',
  generateButton: 'generate-button',
  outputFolderButton: 'output-folder-button',
};

export const outputsReviewPanel = {
  statusTable: 'outputs-review-status-table',
  allFilter: 'outputs-review-all-filter',
  successFilter: 'outputs-review-success-filter',
  failedFilter: 'outputs-review-failed-filter',
  downloadButton: 'outputs-review-download-button',
  openOutputLocationButton: 'outputs-review-open-output-location-button',
  circularProgress: 'outputs-review-circular-progress',
  reviewSidebarIcon: `${sidebarNavTestIds.sidebarItemButton}-${text.outputsSubNavs.review}`,
  downloadLogsButton: 'outputs-review-download-logs-button',
  logData: 'outputs-review-log-data',
  logDataClose: 'outputs-review-log-close',
  logDownload: 'outputs-review-log-download',
  instancesReviewLink: 'instances-review-link',
  instancesReviewElementId: 'instances-review-element-id',
  okButton: 'instances-review-ok-button',
  elementIds: 'elementIds',
  instancesResultDialog: 'instances-result-dialog',
};

export const InstancesPanelIds = {
  expandTableButton: 'instance-panel-expand-table-button',
};

export const SelectionFilterIds = {
  selectionFilterToggleAll: 'selection-filter-toggle-all',
  selectionFilterToggleSelected: 'selection-filter-toggle-selected',
  selectionFilterToggleNotSelected: 'selection-filter-toggle-not-selected',
};

export const releasesPageIds = {
  productsSearch: 'products-search',
  productsList: 'products-list',
  buttonShowHideDetails: 'button-show-hide-details',
};

export const releasesDetailsIds = {
  releaseDetailsThumbnail: 'release-details-thumbnail',
  releaseDetailsSummary: 'release-details-summary',
  releaseDetailsCloseButton: 'release-details-close-button',
};

export const UploadLocationIds = {
  breadcrumpsContent: 'breadcrumps-content',
  selectButton: 'select-button',
  addSubFolderButton: 'add-subfolder-button',
  tooltipIconButton: 'tooltip-icon-button',
};

export const customPagination = {
  nextButton: 'next-button',
  previousButton: 'previous-button',
  firstPageButton: 'first-page-button',
  lastPageButton: 'last-page-button',
};

export const detailsSidebarIds = {
  detailsSidebarWrapper: 'details-sidebar-wrapper',
  detailsSidebarHeader: 'details-sidebar-header',
  detailsSidebarContent: 'details-sidebar-content',
};

export const releaseUpdateIds = {
  releaseUpdateUpdateButton: 'release-update-update-button',
  releaseUpdateCancelButton: 'release-update-cancel-button',
  releaseUpdateWarning: 'release-update-warning',
  releaseUpdateButton: 'release-update-button',
  releaseUpdateCurrentStatus: 'release-update-current-status',
  releaseUpdateOptions: 'release-update-options',
  releaseUpdateSelectNewDefault: 'release-update-select-new-default',
};

export const accountProjectSelectorTestIds = {
  projectList: 'project-list',
  projectSelectorBanner: 'project-selector-banner',
  searchInput: 'account-project-selector-search-input',
  cancelSearchButton: 'account-project-selector-cancel-search-button',
};

export const licensingTestIds = {
  sectionsLegend: 'sections-legend',
  buttonPrint: 'button-print',
  privacyId: 'privacy',
  trademarksId: 'trademarks',
  patentsId: 'patents',
  componentsId: 'components',
  introductionSection: 'introduction-section',
  privacySection: 'privacy-section',
  trademarksSection: 'trademarks-section',
  patentsSection: 'patents-section',
  componentsSection: 'components-section',
  generalTermsPage: 'general-terms-page',
  privacyStatementPage: 'privacy-statement-page',
  trademarksPage: 'trademarks-page',
  patentsPage: 'patents-page',
  cloudPlatformComponentsPage: 'cloud-platform-components-page',
  desktopPlatformComponentsPage: 'desktop-platform-components-page',
};

export const installersTestIds = {
  productTitle: 'product-title',
  downloadInformation: 'download-information',
  downloadButton: 'download-button',
  productPageLink: 'product-page-link',
  largeThumbnail: 'large-thumbnail',
  smallThumbnailWrapper: 'small-thumbnail-wrapper',
};

export const foldersTreeTestIds = {
  newFolderInput: 'new-folder-input',
  confirmNewFolderButton: 'confirm-new-folder-button',
  subFolderCreationMainContainer: 'sub-folder-creation-main-container',
  subFolderCreationLoadingSpinner: 'sub-folder-creation-loading-spinner',
  subFolderCreationErrorIcon: 'sub-folder-creation-error-icon',
  folderIcon: 'folder-icon',
  folderOpenIcon: 'folder-open-icon',
  filledFolderOpenIcon: 'filled-folder-open-icon',
  filledFolderIcon: 'filled-folder-icon',
  lockedFolderIcon: 'locked-folder-icon',
  lockedFolderOpenIcon: 'locked-folder-open-icon',
};

export const uploadLocationProjectSelectorTestIds = {
  projectListDropdown: 'upload-location-project-selector-dropdown',
  loading: 'upload-location-project-selector-loading',
  bim360ProjectIconType: 'upload-location-project-selector-bim360-icon-type',
  accProjectIconType: 'upload-location-project-selector-acc-icon-type',
  fusionProjectIconType: 'upload-location-project-selector-fusion-icon-type',
};
