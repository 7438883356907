import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { DataGridPremium } from '@weave-mui/data-grid';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

export const ProductSelectionWrapper = styled(Box)`
  flex: 0 1 auto;
  min-width: 350px;
`;

export const ProductSelectionList = styled(Box)`
  height: calc(100% - ${({ theme }) => `${theme.var.productsSearchFieldHeight}px`});
  overflow: auto;
`;

export const DataGridPremiumWrapper = styled(DataGridPremium)`
  height: calc(100vh - ${({ theme }) => theme.var.midwTopBars + theme.var.releasesControlsHeight}px);
  border: none;
  & .MuiDataGrid-columnHeaders {
    background-color: ${({ theme }) => theme.colors.surface.lightGray[10]};
  }
`;

export const ReleasesWrapper = styled(Box)`
  flex: 3;
  overflow: auto;
`;

export const ReleasesControlsWrapper = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.var.marginBase}px;
  margin: ${({ theme }) => `${theme.var.marginBase * 1.5}px ${theme.var.marginBase * 2}px`};
`;

export const ReleaseStatusRow = styled('div')`
  display: flex;
  align-items: flex-start;
  margin: ${({ theme }) => theme.var.marginBase * 2}px 0px;
`;

export const ReleaseStatusLabel = styled(Typography)`
  display: flex;
  align-items: center;
  min-width: ${({ theme }) => theme.var.releaseStatusMinWidth}px;
`;

export const ReleaseStatusSelect = styled(Select<number>)`
  min-width: ${({ theme }) => theme.var.releaseStatusMinWidth}px;
`;

export const ReleaseProductName = styled('span')`
  font-weight: bold;
  padding-right: ${({ theme }) => theme.var.paddingBase}px;
  float: left;
`;

export const ReleaseStatusOptionsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.var.paddingBase}px;
`;

export const ReleaseStatusUpdateSpinner = styled(CircularProgress)`
  color: ${({ theme }) => theme.colors.brand.white};
`;
