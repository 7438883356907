import {
  CompleteUploadPayload,
  CompleteUploadResponse,
  DownloadURLPayload,
  DownloadUrlResponse,
  DynamicContent,
  GetVariantLogResponse,
  OutputTypes,
  PostProductPayload,
  PostVariantPayload,
  ProductRelease,
  ProductUserConfiguration,
  PutProductPayload,
  PutProductUserConfigurationPayload,
  ReleaseStatusValue,
  SignedUploadUrlsResponse,
  UploadURLsPayload,
  Variant,
  VariantOutput,
  GetUploadOutputsRequestResponse,
  GetCanGenerateOutputsResponse,
} from '@adsk/offsite-dc-sdk';
import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import * as apiService from './api.service';
import type { AuthHandler, Environment, ForgeQueryResponse } from 'mid-types';
import { InversifyTypes } from './inversify/inversifyTypes';
import { LDContext } from 'launchdarkly-js-sdk-common';


@injectable()
export class DcApiService {
  private apiService: apiService.ApiService;

  constructor(
    @inject(InversifyTypes.DcApiBaseURL) baseURL: string,
    @inject(InversifyTypes.AuthHandler) authHandler: AuthHandler,
    @inject(InversifyTypes.Env) env: Environment,
  ) {
    this.apiService = new apiService.ApiService(baseURL, '', env, {}, authHandler);
  }

  //#region Products Service

  /**
   * Creates a product
   * @param projectId
   * @param postProductPayload
   */
  postProduct = async (projectId: string, postProductPayload: PostProductPayload): Promise<DynamicContent> => {
    const postProductEndpoint = `projects/${projectId}/products`;
    const response = await this.apiService.post(postProductEndpoint, postProductPayload);

    return response.data;
  };

  /**
   * Gets a product
   * @param projectId
   * @param productId
   */
  getProduct = async (projectId: string, productId: string): Promise<DynamicContent> => {
    const getProductEndpoint = `projects/${projectId}/products/${productId}`;
    const response = await this.apiService.get(getProductEndpoint);

    return response.data;
  };

  /**
   * Gets products list
   * @param projectId
   */
  getProductsList = async (projectId: string): Promise<DynamicContent[]> => {
    const getProductsListEndpoint = `projects/${projectId}/products`;

    const productsList: DynamicContent[] = [];

    let nextUrl: string | undefined = getProductsListEndpoint;

    while (typeof nextUrl !== 'undefined') {
      const response = await this.apiService.get(nextUrl);
      const { pagination, results } = response.data as ForgeQueryResponse<DynamicContent>;
      productsList.push(...results);
      nextUrl = pagination.nextUrl;
    }

    return productsList;
  };

  /**
   * Updates a product
   * @param projectId
   * @param productId
   * @param putProductPayload
   */
  updateProduct = async (
    projectId: string,
    productId: string,
    putProductPayload: PutProductPayload,
  ): Promise<DynamicContent> => {
    const updateProductEndpoint = `projects/${projectId}/products/${productId}`;
    const response = await this.apiService.put(updateProductEndpoint, putProductPayload);

    return response.data;
  };

  /**
   * Deletes or archives a product
   * @param projectId
   * @param productId
   * @param archive
   */
  deleteProduct = async (projectId: string, productId: string, archive?: boolean): Promise<DynamicContent | null> => {
    const configOptions: apiService.RequestOptions = {
      params: { archive },
    };

    const deleteProductEndpoint = `projects/${projectId}/products/${productId}`;
    const response = await this.apiService.delete(deleteProductEndpoint, configOptions);

    return response.data;
  };

  //#endregion

  //#region Products Releases Service

  /**
   * Gets a product release
   * @param projectId
   * @param productId
   * @param releaseNumber
   */
  getProductRelease = async (projectId: string, productId: string, releaseNumber: number): Promise<ProductRelease> => {
    const getProductReleaseEndpoint = `projects/${projectId}/products/${productId}/releases/${releaseNumber}`;
    const response = await this.apiService.get(getProductReleaseEndpoint);

    return response.data;
  };

  /**
   * Gets product releases list
   * @param projectId
   * @param productId
   */
  getProductReleasesList = async (projectId: string, productId: string): Promise<ProductRelease[]> => {
    const getProductReleasesListEndpoint = `projects/${projectId}/products/${productId}/releases`;

    const productReleasesList: ProductRelease[] = [];
    let nextUrl: string | undefined = getProductReleasesListEndpoint;

    while (typeof nextUrl !== 'undefined') {
      const response = await this.apiService.get(nextUrl);
      const { pagination, results } = response.data as ForgeQueryResponse<ProductRelease>;
      productReleasesList.push(...results);
      nextUrl = pagination.nextUrl;
    }

    return productReleasesList;
  };

  /**
   * Updates a product release
   * @param projectId
   * @param productId
   * @param release
   * @param status
   */
  updateProductRelease = async (
    projectId: string,
    productId: string,
    release: number,
    status: ReleaseStatusValue,
  ): Promise<ProductRelease> => {
    const updateProductReleaseEndpoint = `projects/${projectId}/products/${productId}/releases/${release}`;
    const response = await this.apiService.patch(updateProductReleaseEndpoint, { status });

    return response.data;
  };

  //#endregion

  //#region Variants Service

  /**
   * Creates a variant
   * @param projectId
   * @param productId
   * @param postVariantPayload
   */
  postVariant = async (projectId: string, productId: string, postVariantPayload: PostVariantPayload): Promise<Variant> => {
    const postVariantEndpoint = `projects/${projectId}/products/${productId}/variants`;
    const response = await this.apiService.post(postVariantEndpoint, postVariantPayload);

    return response.data;
  };

  /**
   * Gets a variant
   * @param projectId
   * @param productId
   * @param variantId
   */
  getVariant = async (projectId: string, productId: string, variantId: string): Promise<Variant> => {
    const getVariantEndpoint = `projects/${projectId}/products/${productId}/variants/${variantId}`;
    const response = await this.apiService.get(getVariantEndpoint);

    return response.data;
  };

  /**
   * Gets variants list
   * @param projectId
   * @param productId
   */
  getVariantsList = async (projectId: string, productId: string): Promise<Variant[]> => {
    const getVariantsListEndpoint = `projects/${projectId}/products/${productId}/variants`;

    const variantsList: Variant[] = [];

    let nextUrl: string | undefined = getVariantsListEndpoint;

    while (typeof nextUrl !== 'undefined') {
      const response = await this.apiService.get(nextUrl);
      const { pagination, results } = response.data as ForgeQueryResponse<Variant>;
      variantsList.push(...results);
      nextUrl = pagination.nextUrl;
    }

    return variantsList;
  };

  /**
   * Gets variant outputs
   * @param projectId
   * @param productId
   * @param variantId
   */
  getVariantOutputs = async (projectId: string, productId: string, variantId: string): Promise<VariantOutput[]> => {
    const getVariantOutputsEndpoint = `projects/${projectId}/products/${productId}/variants/${variantId}/outputs`;
    const response = await this.apiService.get(getVariantOutputsEndpoint);

    return response.data;
  };

  /**
   * Gets variant output by type
   * @param projectId
   * @param productId
   * @param variantId
   * @param outputType
   */
  getVariantOutputsByType = async (
    projectId: string,
    productId: string,
    variantId: string,
    type: OutputTypes,
  ): Promise<VariantOutput[]> => {
    const configOptions: apiService.RequestOptions = {
      params: { type },
    };

    const getVariantOutputsByTypeEndpoint = `projects/${projectId}/products/${productId}/variants/${variantId}/outputs`;
    const response = await this.apiService.get(getVariantOutputsByTypeEndpoint, configOptions);

    return response.data;
  };

  /**
   * Deletes a variant
   * @param projectId
   * @param productId
   * @param variantId
   */
  deleteVariant = async (projectId: string, productId: string, variantId: string): Promise<any> => {
    const deleteVariantEndpoint = `projects/${projectId}/products/${productId}/variants/${variantId}`;
    const response = await this.apiService.delete(deleteVariantEndpoint);

    return response.data;
  };

  /**
   * Delete variants
   * @param projectId
   * @param productId
   */
  deleteVariants = async (projectId: string, productId: string): Promise<any> => {
    const deleteVariantsEndpoint = `projects/${projectId}/products/${productId}/variants`;
    const response = await this.apiService.delete(deleteVariantsEndpoint);

    return response.data;
  };

  /**
   * Gets a variant log
   * @param projectId
   * @param productId
   * @param variantId
   */
  getVariantLog = async (projectId: string, productId: string, variantId: string): Promise<GetVariantLogResponse> => {
    const getVariantLogEndpoint = `projects/${projectId}/products/${productId}/variants/${variantId}/logs`;
    const response = await this.apiService.get(getVariantLogEndpoint);

    return response.data;
  };

  //#endregion

  //#region User Configurations Service
  updateProductUserConfiguration = async (
    projectId: string,
    productId: string,
    productUserConfigurationPayload: PutProductUserConfigurationPayload,
  ): Promise<ProductUserConfiguration> => {
    const putProductUserConfigPath = `userconfigs/projects/${projectId}/products/${productId}`;
    const response = await this.apiService.put(putProductUserConfigPath, productUserConfigurationPayload);

    return response.data;
  };

  canGenerateOutputs = async <T = GetCanGenerateOutputsResponse>(requestedOutputsCount: number): Promise<T> =>
    (
      await this.apiService.get<T>('userconfigs/cangenerateoutputs', {
        params: {
          requestedOutputsCount,
        },
      })
    ).data;

  //#endregion

  //#region Data Service

  /**
   * Gets an array of signed URLs to upload an object in multiple parts.
   * @param projectId
   * @param uploadURLsPayload
   */
  getUploadURLs = async (projectId: string, uploadURLsPayload: UploadURLsPayload): Promise<SignedUploadUrlsResponse> => {
    const getUploadURLsEndpoint = `projects/${projectId}/data/uploadurls`;
    const response = await this.apiService.post(getUploadURLsEndpoint, uploadURLsPayload);

    return response.data;
  };

  /**
   * Marks completion of the object's upload process after its multiple parts have been uploaded.
   * @param projectId
   * @param completeUploadPayload
   */
  completeUpload = async (
    projectId: string,
    completeUploadPayload: CompleteUploadPayload,
  ): Promise<CompleteUploadResponse> => {
    const completeUploadEndpoint = `projects/${projectId}/data/completeupload`;
    const response = await this.apiService.post(completeUploadEndpoint, completeUploadPayload);

    return response.data;
  };

  /**
   * Gets a signed URL to a download file.
   * @param projectId
   * @param downloadURLPayload
   */
  downloadURL = async (projectId: string, downloadURLPayload: DownloadURLPayload): Promise<DownloadUrlResponse> => {
    const downloadURLEndpoint = `projects/${projectId}/data/downloadurl`;
    const response = await this.apiService.post(downloadURLEndpoint, downloadURLPayload);

    return response.data;
  };

  //#endregion

  getVariantOutputUploadRequests = async (
    projectId: string,
    productId: string,
    variantId: string,
    signal: AbortSignal,
  ): Promise<GetUploadOutputsRequestResponse[]> => {
    const getVariantOutputUploadRequestsEndpoint = `projects/${projectId}/products/${productId}/variants/${variantId}/uploadrequests`;

    const uploadResults: GetUploadOutputsRequestResponse[] = [];
    let nextUrl: string | undefined = getVariantOutputUploadRequestsEndpoint;

    while (typeof nextUrl !== 'undefined') {
      const response = await this.apiService.get(nextUrl, { signal });

      const { pagination, results } = response.data as ForgeQueryResponse<GetUploadOutputsRequestResponse>;
      uploadResults.push(...results);
      nextUrl = pagination.nextUrl;
    }

    return uploadResults;
  };

  getHashForLaunchdarkly = async (context: LDContext): Promise<string> => {
    const ldServerHashUrl = `ld_server_hash`;
    const response = await this.apiService.post(ldServerHashUrl, context);

    return response.data;
  }
}
